import { ChakraProvider } from "@chakra-ui/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
// https://github.com/import-js/eslint-plugin-import/issues/1479
// eslint-disable-next-line import/no-duplicates
import { setDefaultOptions } from "date-fns"
// eslint-disable-next-line import/no-duplicates
import frLocale from "date-fns/locale/fr"
import "leaflet/dist/leaflet.css"
import "react-image-gallery/styles/css/image-gallery.css"
import { IntlProvider } from "react-intl"
import "react-phone-number-input/style.css"
import { BrowserRouter } from "react-router-dom"
import { AppRefLabelsContext } from "./context/AppRefLabelsContext"

import AuthenticatedUserContextProvider from "./context/AuthenticatedUserContext/AuthenticatedUserContext"
import AxiosInterceptors from "./networking/AxiosInterceptors"
import { AppRoutes } from "./routing/AppRoutes"
import { theme } from "./theme/theme"
import { frenchMessages } from "./translations/fr/frenchMessages"

setDefaultOptions({ locale: frLocale })

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

export default function App() {
  return (
    <IntlProvider locale="fr" messages={frenchMessages}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AxiosInterceptors>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <AppRefLabelsContext>
                <AuthenticatedUserContextProvider>
                  <AppRoutes />
                </AuthenticatedUserContextProvider>
              </AppRefLabelsContext>
            </QueryClientProvider>
          </AxiosInterceptors>
        </BrowserRouter>
      </ChakraProvider>
    </IntlProvider>
  )
}
